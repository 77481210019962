import axios from 'axios';
import { get } from 'lodash-es';
const IS_PRODUCTION = !process.env.VUE_APP_USER;

export default async (id) => {
  if (IS_PRODUCTION) { console.warn('Вызов функции не доступен в production режиме'); return }
  const admin = process.env.VUE_APP_USER || '';
  const base = process.env.VUE_APP_REST_ROOT || '';
  await axios('/bitrix/admin/?logout=yes');
  const headers = { Authorization: `Basic ${new Buffer(admin).toString('base64')}` };
  let sessid = '';
  try {
    const response = await axios(base + '/user/current.json', { headers, params: { sessid: '' }});
    sessid = get(response, 'data.auth.sessid', '');
  } catch (response) {
    sessid = get(response, 'response.data.sessid', '');
  }
  await axios('/bitrix/admin/user_edit.php', {
    params: { ID: id || window.prompt('Пользователь?'), lang: 'ru', sessid: sessid, action: 'authorize' },
  });
  window.location.href = ''+window.location.href;
}
